import Grid from "@mui/material/Grid";

const whatsIncludedStyles: { [key: string]: React.CSSProperties } = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: 20,
    textAlign: "center",
    textWrap: "balance",
  },
  image: {
    marginBottom: 8,
  },
};

type Image = {
  src: string;
  alt: string;
};

const WhatsIncluded: React.FC<{ images: Image[]; boxStyle: "white-outline-box" | "teal-box" }> = ({
  images,
  boxStyle,
}) => {
  return (
    <div className={boxStyle} style={{ paddingLeft: 40, paddingRight: 40 }}>
      <h4 className="box-title">What's Included</h4>

      <Grid container spacing={2} width={"100%"}>
        {images.map((image, idx) => (
          <Grid item style={whatsIncludedStyles.container} sm={3} key={idx}>
            <img src={image.src} alt={image.alt} className="box-shadow" style={whatsIncludedStyles.image} />
            <p>{image.alt}</p>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default WhatsIncluded;
