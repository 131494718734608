import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { handleCDMPStudyProgramPurchase } from "../../../helpers/checkoutUtils";

export default function CDMPStudyProgramCheckoutMobile({
  count,
  setInputValue,
  increment,
  decrement,
  price,
  history,
  setStep,
  setShowText,
}) {
  return (
    <div style={{ maxWidth: 300, margin: "20px auto 28px auto" }}>
      <div
        style={{
          display: "flex",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            paddingRight: "16px",
          }}
        >
          <input
            value={count}
            type="number"
            id="count"
            data-testid="count"
            onChange={(e) => setInputValue(e.target.value)}
            className="field enterprise-modal-input"
            style={{ borderRadius: "8px", width: "200px !important" }}
            min="4"
            disabled
          />
          <p style={{ paddingLeft: "8px" }}>participants</p>
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
          <ArrowDropUpIcon fontSize="large" color="disabled" onClick={increment} data-testid="ArrowDropUpIcon" />
          <ArrowDropDownIcon fontSize="large" color="disabled" onClick={decrement} data-testid="ArrowDropDownIcon" />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 10,
          margin: "-.8em 0 -.5em 8px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
          <p style={{ fontSize: "2.4em", marginBottom: "8px", fontWeight: "500", color: "#959393" }}> = </p>
          <p
            style={{ margin: "-.2em 10px 0 0", fontSize: "1.6em", fontWeight: 900, textAlign: "center" }}
            data-testid="price"
          >
            {price}
          </p>
        </div>
      </div>

      <button
        className="btn btn-accent"
        type="submit"
        style={{
          padding: "10px 20px",
          position: "relative",
          width: "100%",
        }}
        onClick={(event) => handleCDMPStudyProgramPurchase(event, count, history, setStep, setShowText)}
      >
        Purchase
      </button>

      <p style={{ marginTop: "1.5em", fontStyle: "italic", textAlign: "center" }}>Minimum 4 participants</p>
    </div>
  );
}
