const events: eventDetails[] = [
  // {
  //   id: "jan-bootcamp",
  //   type: "Bootcamp",
  //   name: "January Bootcamp",
  //   start: new Date("January 10, 2025 9:00:00"),
  //   end: new Date("January 11, 2025 16:00:00"),
  //   link: "https://www.datastrategypros.com/products/virtual-cdmp-bootcamp",
  //   image: "https://www.datastrategypros.com/static/media/jan.7030e53fbae8a062e823.webp",
  // },
  {
    id: "jan-cdmp-prep-a",
    type: "CDMP Prep Session",
    name: "January CDMP Prep (ch.<span>&nbsp;</span>1 & 2)",
    start: new Date("January 30, 2025 13:00:00"),
    end: new Date("January 30, 2025 14:00:00"),
    chapterName: "Data Management",
    chapterNumber: 1,
    link: "https://lu.ma/em8houiv",
    image:
      "https://images.lumacdn.com/cdn-cgi/image/format=auto,fit=cover,dpr=1,background=white,quality=75,width=400,height=400/event-covers/re/d7ac3180-9025-4e62-a145-dd7e140fd65c",
  },
  {
    id: "jan-cdmp-prep-b",
    type: "CDMP Prep Session",
    name: "January CDMP Prep (ch.<span>&nbsp;</span>1 & 2)",
    start: new Date("January 30, 2025 18:00:00"),
    end: new Date("January 30, 2025 19:00:00"),
    chapterName: "Data Management",
    chapterNumber: 1,
    link: "https://lu.ma/2f8mfx7e",
    image:
      "https://images.lumacdn.com/cdn-cgi/image/format=auto,fit=cover,dpr=1,background=white,quality=75,width=400,height=400/event-covers/re/d7ac3180-9025-4e62-a145-dd7e140fd65c",
  },
  {
    id: "jan-cdmp-prep-c",
    type: "CDMP Prep Session",
    name: "January CDMP Prep (ch.<span>&nbsp;</span>1 & 2)",
    start: new Date("January 31, 2025 6:00:00"),
    end: new Date("January 31, 2025 7:00:00"),
    chapterName: "Data Management",
    chapterNumber: 1,
    link: "https://lu.ma/1uehiu95",
    image:
      "https://images.lumacdn.com/cdn-cgi/image/format=auto,fit=cover,dpr=1,background=white,quality=75,width=400,height=400/event-covers/re/d7ac3180-9025-4e62-a145-dd7e140fd65c",
  },
  {
    id: "business-value",
    type: "presentation",
    name: "Business Value of Leading AI Governance Frameworks",
    start: new Date("Feb 6, 2025 12:00:00"),
    end: new Date("Feb 6, 2025 13:00:00"),
    link: "https://lu.ma/3nmlel1f",
    image:
      "https://images.lumacdn.com/cdn-cgi/image/format=auto,fit=cover,dpr=1,background=white,quality=75,width=400,height=400/event-covers/e9/81c0262b-69f0-4269-9bab-3baafca0cd9c",
  },
  {
    id: "feb-cdmp-prep-a",
    type: "CDMP Prep Session",
    name: "February CDMP Prep (ch.<span>&nbsp;</span>3)",
    start: new Date("February 27, 2025 13:00:00"),
    end: new Date("February 27, 2025 14:00:00"),
    chapterName: "Data Governance",
    chapterNumber: 3,
    link: "https://lu.ma/tovhlr1l",
    image:
      "https://images.lumacdn.com/cdn-cgi/image/format=auto,fit=cover,dpr=1,background=white,quality=75,width=400,height=400/event-covers/re/d7ac3180-9025-4e62-a145-dd7e140fd65c",
  },
  {
    id: "feb-cdmp-prep-b",
    type: "CDMP Prep Session",
    name: "February CDMP Prep (ch.<span>&nbsp;</span>3)",
    start: new Date("February 27, 2025 18:00:00"),
    end: new Date("February 27, 2025 19:00:00"),
    link: "https://lu.ma/kripfhc0",
    chapterName: "Data Governance",
    chapterNumber: 3,
    image:
      "https://images.lumacdn.com/cdn-cgi/image/format=auto,fit=cover,dpr=1,background=white,quality=75,width=400,height=400/event-covers/re/d7ac3180-9025-4e62-a145-dd7e140fd65c",
  },
  {
    id: "feb-cdmp-prep-c",
    type: "CDMP Prep Session",
    name: "February CDMP Prep (ch.<span>&nbsp;</span>3)",
    start: new Date("February 28, 2025 6:00:00"),
    end: new Date("February 28, 2025 7:00:00"),
    link: "https://lu.ma/oyl5r70u",
    chapterName: "Data Governance",
    chapterNumber: 3,
    image:
      "https://images.lumacdn.com/cdn-cgi/image/format=auto,fit=cover,dpr=1,background=white,quality=75,width=400,height=400/event-covers/re/d7ac3180-9025-4e62-a145-dd7e140fd65c",
  },
  {
    id: "mar-cdmp-prep-a",
    type: "CDMP Prep Session",
    name: "March CDMP Prep (ch.<span>&nbsp;</span>4)",
    start: new Date("March 27, 2025 13:00:00"),
    end: new Date("March 27, 2025 14:00:00"),
    link: "https://lu.ma/lrxog4rb",
    chapterName: "Data Architecture",
    chapterNumber: 4,
    image:
      "https://images.lumacdn.com/cdn-cgi/image/format=auto,fit=cover,dpr=1,background=white,quality=75,width=400,height=400/event-covers/re/d7ac3180-9025-4e62-a145-dd7e140fd65c",
  },
  {
    id: "mar-cdmp-prep-b",
    type: "CDMP Prep Session",
    name: "March CDMP Prep (ch.<span>&nbsp;</span>4)",
    start: new Date("March 27, 2025 18:00:00"),
    end: new Date("March 27, 2025 19:00:00"),
    chapterName: "Data Architecture",
    chapterNumber: 4,
    link: "https://lu.ma/98in1984",
    image:
      "https://images.lumacdn.com/cdn-cgi/image/format=auto,fit=cover,dpr=1,background=white,quality=75,width=400,height=400/event-covers/re/d7ac3180-9025-4e62-a145-dd7e140fd65c",
  },
  {
    id: "mar-cdmp-prep-c",
    type: "CDMP Prep Session",
    name: "March CDMP Prep (ch.<span>&nbsp;</span>4)",
    start: new Date("March 28, 2025 6:00:00"),
    end: new Date("March 28, 2025 7:00:00"),
    chapterName: "Data Architecture",
    chapterNumber: 4,
    link: "https://lu.ma/4c9xhior",
    image:
      "https://images.lumacdn.com/cdn-cgi/image/format=auto,fit=cover,dpr=1,background=white,quality=75,width=400,height=400/event-covers/re/d7ac3180-9025-4e62-a145-dd7e140fd65c",
  },
  {
    id: "apr-cdmp-prep-a",
    type: "CDMP Prep Session",
    name: "April CDMP Prep (ch.<span>&nbsp;</span>5)",
    start: new Date("April 24, 2025 13:00:00"),
    end: new Date("April 24, 2025 14:00:00"),
    chapterName: "Data Modeling",
    chapterNumber: 5,
    link: "https://lu.ma/nnp3ufgb",
    image:
      "https://images.lumacdn.com/cdn-cgi/image/format=auto,fit=cover,dpr=1,background=white,quality=75,width=400,height=400/event-covers/re/d7ac3180-9025-4e62-a145-dd7e140fd65c",
  },
  {
    id: "apr-cdmp-prep-b",
    type: "CDMP Prep Session",
    name: "April CDMP Prep (ch.<span>&nbsp;</span>5)",
    start: new Date("April 24, 2025 18:00:00"),
    end: new Date("April 24, 2025 19:00:00"),
    chapterName: "Data Modeling",
    chapterNumber: 5,
    link: "https://lu.ma/lvbx0sok",
    image:
      "https://images.lumacdn.com/cdn-cgi/image/format=auto,fit=cover,dpr=1,background=white,quality=75,width=400,height=400/event-covers/re/d7ac3180-9025-4e62-a145-dd7e140fd65c",
  },
  {
    id: "apr-cdmp-prep-c",
    type: "CDMP Prep Session",
    name: "April CDMP Prep (ch.<span>&nbsp;</span>5)",
    start: new Date("April 25, 2025 6:00:00"),
    end: new Date("April 25, 2025 7:00:00"),
    chapterName: "Data Modeling",
    chapterNumber: 5,
    link: "https://lu.ma/ita23ipe",
    image:
      "https://images.lumacdn.com/cdn-cgi/image/format=auto,fit=cover,dpr=1,background=white,quality=75,width=400,height=400/event-covers/re/d7ac3180-9025-4e62-a145-dd7e140fd65c",
  },
  {
    id: "may-cdmp-prep-a",
    type: "CDMP Prep Session",
    name: "May CDMP Prep (ch.<span>&nbsp;</span>6)",
    start: new Date("May 29, 2025 13:00:00"),
    end: new Date("May 29, 2025 14:00:00"),
    chapterName: "Data Storage",
    chapterNumber: 6,
    link: "https://lu.ma/788gb4pm",
    image:
      "https://images.lumacdn.com/cdn-cgi/image/format=auto,fit=cover,dpr=1,background=white,quality=75,width=400,height=400/event-covers/re/d7ac3180-9025-4e62-a145-dd7e140fd65c",
  },
  {
    id: "may-cdmp-prep-b",
    type: "CDMP Prep Session",
    name: "May CDMP Prep (ch.<span>&nbsp;</span>6)",
    start: new Date("May 29, 2025 18:00:00"),
    end: new Date("May 29, 2025 19:00:00"),
    chapterName: "Data Storage",
    chapterNumber: 6,
    link: "https://lu.ma/4ikxyn8q",
    image:
      "https://images.lumacdn.com/cdn-cgi/image/format=auto,fit=cover,dpr=1,background=white,quality=75,width=400,height=400/event-covers/re/d7ac3180-9025-4e62-a145-dd7e140fd65c",
  },
  {
    id: "may-cdmp-prep-c",
    type: "CDMP Prep Session",
    name: "May CDMP Prep (ch.<span>&nbsp;</span>6)",
    start: new Date("May 30, 2025 6:00:00"),
    end: new Date("May 30, 2025 7:00:00"),
    chapterName: "Data Storage",
    chapterNumber: 6,
    link: "https://lu.ma/9g9vjeyu",
    image:
      "https://images.lumacdn.com/cdn-cgi/image/format=auto,fit=cover,dpr=1,background=white,quality=75,width=400,height=400/event-covers/re/d7ac3180-9025-4e62-a145-dd7e140fd65c",
  },
  {
    id: "jun-cdmp-prep-a",
    type: "CDMP Prep Session",
    name: "June CDMP Prep (ch.<span>&nbsp;</span>7)",
    start: new Date("June 26, 2025 13:00:00"),
    end: new Date("June 26, 2025 14:00:00"),
    chapterName: "Data Security",
    chapterNumber: 7,
    link: "https://lu.ma/gj5v6o1q",
    image:
      "https://images.lumacdn.com/cdn-cgi/image/format=auto,fit=cover,dpr=1,background=white,quality=75,width=400,height=400/event-covers/re/d7ac3180-9025-4e62-a145-dd7e140fd65c",
  },
  {
    id: "jun-cdmp-prep-b",
    type: "CDMP Prep Session",
    name: "June CDMP Prep (ch.<span>&nbsp;</span>7)",
    start: new Date("June 26, 2025 18:00:00"),
    end: new Date("June 26, 2025 19:00:00"),
    chapterName: "Data Security",
    chapterNumber: 7,
    link: "https://lu.ma/xz18gwp9",
    image:
      "https://images.lumacdn.com/cdn-cgi/image/format=auto,fit=cover,dpr=1,background=white,quality=75,width=400,height=400/event-covers/re/d7ac3180-9025-4e62-a145-dd7e140fd65c",
  },
  {
    id: "jun-cdmp-prep-c",
    type: "CDMP Prep Session",
    name: "June CDMP Prep (ch.<span>&nbsp;</span>7)",
    start: new Date("June 27, 2025 6:00:00"),
    end: new Date("June 27, 2025 7:00:00"),
    chapterName: "Data Security",
    chapterNumber: 7,
    link: "https://lu.ma/doc2gp8d",
    image:
      "https://images.lumacdn.com/cdn-cgi/image/format=auto,fit=cover,dpr=1,background=white,quality=75,width=400,height=400/event-covers/re/d7ac3180-9025-4e62-a145-dd7e140fd65c",
  },
  {
    id: "jul-cdmp-prep-a",
    type: "CDMP Prep Session",
    name: "July CDMP Prep (ch.<span>&nbsp;</span>8)",
    start: new Date("July 31, 2025 13:00:00"),
    end: new Date("July 31, 2025 14:00:00"),
    chapterName: "Data Integration & Interoperability",
    chapterNumber: 8,
    link: "https://lu.ma/x6f9n25g",
    image:
      "https://images.lumacdn.com/cdn-cgi/image/format=auto,fit=cover,dpr=1,background=white,quality=75,width=400,height=400/event-covers/re/d7ac3180-9025-4e62-a145-dd7e140fd65c",
  },
  {
    id: "jul-cdmp-prep-b",
    type: "CDMP Prep Session",
    name: "July CDMP Prep (ch.<span>&nbsp;</span>8)",
    start: new Date("July 31, 2025 18:00:00"),
    end: new Date("July 31, 2025 19:00:00"),
    chapterName: "Data Integration & Interoperability",
    chapterNumber: 8,
    link: "https://lu.ma/rbnuw6j5",
    image:
      "https://images.lumacdn.com/cdn-cgi/image/format=auto,fit=cover,dpr=1,background=white,quality=75,width=400,height=400/event-covers/re/d7ac3180-9025-4e62-a145-dd7e140fd65c",
  },
  {
    id: "jul-cdmp-prep-c",
    type: "CDMP Prep Session",
    name: "July CDMP Prep (ch.<span>&nbsp;</span>8)",
    start: new Date("August 1, 2025 6:00:00"),
    end: new Date("August 1, 2025 7:00:00"),
    chapterName: "Data Integration & Interoperability",
    chapterNumber: 8,
    link: "https://lu.ma/zq3w32o7",
    image:
      "https://images.lumacdn.com/cdn-cgi/image/format=auto,fit=cover,dpr=1,background=white,quality=75,width=400,height=400/event-covers/re/d7ac3180-9025-4e62-a145-dd7e140fd65c",
  },
  {
    id: "aug-cdmp-prep-a",
    type: "CDMP Prep Session",
    name: "August CDMP Prep (ch.<span>&nbsp;</span>9)",
    start: new Date("August 28, 2025 13:00:00"),
    end: new Date("August 28, 2025 14:00:00"),
    chapterName: "Document & Content Management",
    chapterNumber: 9,
    link: "https://lu.ma/mp7yrp8n",
    image:
      "https://images.lumacdn.com/cdn-cgi/image/format=auto,fit=cover,dpr=1,background=white,quality=75,width=400,height=400/event-covers/re/d7ac3180-9025-4e62-a145-dd7e140fd65c",
  },
  {
    id: "aug-cdmp-prep-b",
    type: "CDMP Prep Session",
    name: "August CDMP Prep (ch.<span>&nbsp;</span>9)",
    start: new Date("August 28, 2025 18:00:00"),
    end: new Date("August 28, 2025 19:00:00"),
    chapterName: "Document & Content Management",
    chapterNumber: 9,
    link: "https://lu.ma/tbw4p0m5",
    image:
      "https://images.lumacdn.com/cdn-cgi/image/format=auto,fit=cover,dpr=1,background=white,quality=75,width=400,height=400/event-covers/re/d7ac3180-9025-4e62-a145-dd7e140fd65c",
  },
  {
    id: "aug-cdmp-prep-c",
    type: "CDMP Prep Session",
    name: "August CDMP Prep (ch.<span>&nbsp;</span>9)",
    start: new Date("August 29, 2025 6:00:00"),
    end: new Date("August 29, 2025 7:00:00"),
    chapterName: "Document & Content Management",
    chapterNumber: 9,
    link: "https://lu.ma/w3l8vkca",
    image:
      "https://images.lumacdn.com/cdn-cgi/image/format=auto,fit=cover,dpr=1,background=white,quality=75,width=400,height=400/event-covers/re/d7ac3180-9025-4e62-a145-dd7e140fd65c",
  },
  {
    id: "sep-cdmp-prep-a",
    type: "CDMP Prep Session",
    name: "September CDMP Prep (ch.<span>&nbsp;</span>10)",
    start: new Date("September 25, 2025 13:00:00"),
    end: new Date("September 25, 2025 14:00:00"),
    chapterName: "Master & Reference Data Management",
    chapterNumber: 10,
    link: "https://lu.ma/xjsj7yjs",
    image:
      "https://images.lumacdn.com/cdn-cgi/image/format=auto,fit=cover,dpr=1,background=white,quality=75,width=400,height=400/event-covers/re/d7ac3180-9025-4e62-a145-dd7e140fd65c",
  },
  {
    id: "sep-cdmp-prep-b",
    type: "CDMP Prep Session",
    name: "September CDMP Prep (ch.<span>&nbsp;</span>10)",
    start: new Date("September 25, 2025 18:00:00"),
    end: new Date("September 25, 2025 19:00:00"),
    chapterName: "Master & Reference Data Management",
    chapterNumber: 10,
    link: "https://lu.ma/akylt0uz",
    image:
      "https://images.lumacdn.com/cdn-cgi/image/format=auto,fit=cover,dpr=1,background=white,quality=75,width=400,height=400/event-covers/re/d7ac3180-9025-4e62-a145-dd7e140fd65c",
  },
  {
    id: "sep-cdmp-prep-c",
    type: "CDMP Prep Session",
    name: "September CDMP Prep (ch.<span>&nbsp;</span>10)",
    start: new Date("September 26, 2025 6:00:00"),
    end: new Date("September 26, 2025 7:00:00"),
    chapterName: "Master & Reference Data Management",
    chapterNumber: 10,
    link: "https://lu.ma/ppdpghj8",
    image:
      "https://images.lumacdn.com/cdn-cgi/image/format=auto,fit=cover,dpr=1,background=white,quality=75,width=400,height=400/event-covers/re/d7ac3180-9025-4e62-a145-dd7e140fd65c",
  },
  {
    id: "oct-cdmp-prep-a",
    type: "CDMP Prep Session",
    name: "October CDMP Prep (ch.<span>&nbsp;</span>11)",
    start: new Date("October 30, 2025 13:00:00"),
    end: new Date("October 30, 2025 14:00:00"),
    chapterName: "Data Warehousing & Business Intelligence",
    chapterNumber: 11,
    link: "https://lu.ma/7szmks7z",
    image:
      "https://images.lumacdn.com/cdn-cgi/image/format=auto,fit=cover,dpr=1,background=white,quality=75,width=400,height=400/event-covers/re/d7ac3180-9025-4e62-a145-dd7e140fd65c",
  },
  {
    id: "oct-cdmp-prep-b",
    type: "CDMP Prep Session",
    name: "October CDMP Prep (ch.<span>&nbsp;</span>11)",
    start: new Date("October 30, 2025 18:00:00"),
    end: new Date("October 30, 2025 19:00:00"),
    chapterName: "Data Warehousing & Business Intelligence",
    chapterNumber: 11,
    link: "https://lu.ma/wy3iuk1h",
    image:
      "https://images.lumacdn.com/cdn-cgi/image/format=auto,fit=cover,dpr=1,background=white,quality=75,width=400,height=400/event-covers/re/d7ac3180-9025-4e62-a145-dd7e140fd65c",
  },
  {
    id: "oct-cdmp-prep-c",
    type: "CDMP Prep Session",
    name: "October CDMP Prep (ch.<span>&nbsp;</span>11)",
    start: new Date("October 31, 2025 6:00:00"),
    end: new Date("October 31, 2025 7:00:00"),
    chapterName: "Data Warehousing & Business Intelligence",
    chapterNumber: 11,
    link: "https://lu.ma/yksnq38p",
    image:
      "https://images.lumacdn.com/cdn-cgi/image/format=auto,fit=cover,dpr=1,background=white,quality=75,width=400,height=400/event-covers/re/d7ac3180-9025-4e62-a145-dd7e140fd65c",
  },
  {
    id: "nov-cdmp-prep-a",
    type: "CDMP Prep Session",
    name: "November CDMP Prep (ch.<span>&nbsp;</span>12)",
    start: new Date("November 27, 2025 13:00:00"),
    end: new Date("November 27, 2025 14:00:00"),
    chapterName: "Metadata Management",
    chapterNumber: 12,
    link: "https://lu.ma/ky2h7054",
    image:
      "https://images.lumacdn.com/cdn-cgi/image/format=auto,fit=cover,dpr=1,background=white,quality=75,width=400,height=400/event-covers/re/d7ac3180-9025-4e62-a145-dd7e140fd65c",
  },
  {
    id: "nov-cdmp-prep-b",
    type: "CDMP Prep Session",
    name: "November CDMP Prep (ch.<span>&nbsp;</span>12)",
    start: new Date("November 27, 2025 18:00:00"),
    end: new Date("November 27, 2025 19:00:00"),
    chapterName: "Metadata Management",
    chapterNumber: 12,
    link: "https://lu.ma/pkxsgk8d",
    image:
      "https://images.lumacdn.com/cdn-cgi/image/format=auto,fit=cover,dpr=1,background=white,quality=75,width=400,height=400/event-covers/re/d7ac3180-9025-4e62-a145-dd7e140fd65c",
  },
  {
    id: "nov-cdmp-prep-c",
    type: "CDMP Prep Session",
    name: "November CDMP Prep (ch.<span>&nbsp;</span>12)",
    start: new Date("November 28, 2025 6:00:00"),
    end: new Date("November 28, 2025 7:00:00"),
    chapterName: "Metadata Management",
    chapterNumber: 12,
    link: "https://lu.ma/xw7xy3p0",
    image:
      "https://images.lumacdn.com/cdn-cgi/image/format=auto,fit=cover,dpr=1,background=white,quality=75,width=400,height=400/event-covers/re/d7ac3180-9025-4e62-a145-dd7e140fd65c",
  },
  {
    id: "dec-cdmp-prep-a",
    type: "CDMP Prep Session",
    name: "December CDMP Prep (ch.<span>&nbsp;</span>13 & 14)",
    start: new Date("December 18, 2025 13:00:00"),
    end: new Date("December 18, 2025 14:00:00"),
    chapterName: "Data Quality",
    chapterNumber: 13,
    link: "https://lu.ma/duon17ui",
    image:
      "https://images.lumacdn.com/cdn-cgi/image/format=auto,fit=cover,dpr=1,background=white,quality=75,width=400,height=400/event-covers/re/d7ac3180-9025-4e62-a145-dd7e140fd65c",
  },
  {
    id: "dec-cdmp-prep-b",
    type: "CDMP Prep Session",
    name: "December CDMP Prep (ch.<span>&nbsp;</span>13 & 14)",
    start: new Date("December 18, 2025 18:00:00"),
    end: new Date("December 18, 2025 19:00:00"),
    chapterName: "Data Quality",
    chapterNumber: 13,
    link: "https://lu.ma/s3ac5wkh",
    image:
      "https://images.lumacdn.com/cdn-cgi/image/format=auto,fit=cover,dpr=1,background=white,quality=75,width=400,height=400/event-covers/re/d7ac3180-9025-4e62-a145-dd7e140fd65c",
  },
  {
    id: "dec-cdmp-prep-c",
    type: "CDMP Prep Session",
    name: "December CDMP Prep (ch.<span>&nbsp;</span>13 & 14)",
    start: new Date("December 19, 2025 6:00:00"),
    end: new Date("December 19, 2025 7:00:00"),
    chapterName: "Data Quality",
    chapterNumber: 13,
    link: "https://lu.ma/rbs8rf7c",
    image:
      "https://images.lumacdn.com/cdn-cgi/image/format=auto,fit=cover,dpr=1,background=white,quality=75,width=400,height=400/event-covers/re/d7ac3180-9025-4e62-a145-dd7e140fd65c",
  },
];

export default events;
