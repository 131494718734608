import { handleCDMPStudyProgramPurchase } from "../../../helpers/checkoutUtils";

interface CDMPStudyProgramCheckoutRowProps {
  count: number;
  setInputValue: (value: string) => void;
  setCount: (count: number) => void;
  price: string;
  showText: boolean;
  history: any;
  setStep: (step: number) => void;
  setShowText: (showText: boolean) => void;
}

const CDMPStudyProgramCheckoutRow: React.FC<CDMPStudyProgramCheckoutRowProps> = ({
  count,
  setInputValue,
  setCount,
  price,
  showText,
  history,
  setStep,
  setShowText,
}) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div style={{ marginTop: "1.5em" }}>
            <input
              defaultValue={count}
              type="number"
              id="count"
              data-testid="count"
              min={4} // Set the minimum value
              onChange={(e) => {
                const newValue = parseInt(e.target.value, 10);
                setInputValue(newValue.toString()); // Update the input value
                setCount(newValue); // Update the count state
              }}
              className="field enterprise-modal-input"
              style={{
                borderRadius: "8px",
              }}
            />

            <p style={{ fontSize: ".9em", fontStyle: "italic", textAlign: "center" }}>
              {showText ? "min 4" : <span>&nbsp;&nbsp;</span>}
            </p>
          </div>
          <p style={{ paddingLeft: "8px", margin: "auto", fontSize: "1.1em" }}>participants</p>
        </div>
        <p style={{ fontSize: "1.4em", fontWeight: "700", color: "#959393" }}>x</p>
        <p style={{ fontSize: "1.1em" }}>$399</p>
        <p style={{ fontSize: "2.4em", marginBottom: "8px", fontWeight: "500", color: "#959393" }}> = </p>
        <p style={{ fontSize: "1.2em", fontWeight: 900 }} data-testid="price">
          {price}
        </p>
        <button
          className="btn btn-accent"
          type="submit"
          style={{
            width: "180px",
            padding: "10px 20px",
            margin: "4px 0px 4px 60px",
            fontSize: "1.2em",
          }}
          onClick={(event) => handleCDMPStudyProgramPurchase(event, count, history, setStep, setShowText)}
        >
          Purchase
        </button>
      </div>
    </>
  );
};

export default CDMPStudyProgramCheckoutRow;
