import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

import CDMPStudyProgramCheckoutRow from "./CDMPStudyProgramCheckoutRow";
import CDMPStudyProgramCheckoutMobile from "./CDMPStudyProgramCheckoutMobile";

import { formatCurrencySimple } from "../../../helpers/utils";

const CDMPStudyProgramCheckoutController: React.FC<{
  count: number;
  setCount: (count: number) => void;
  setStep: (step: number) => void;
}> = ({ count, setCount, setStep }) => {
  const [inputValue, setInputValue] = useState<string>("");
  const [showText, setShowText] = useState<boolean>(false);
  const showDesktop = useMediaQuery("(min-width:900px)");

  const history = useHistory();

  const UNIT_PRICE = 39900;

  useEffect(() => {
    if (parseInt(inputValue) >= 4) {
      setCount(parseInt(inputValue));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  const price = formatCurrencySimple(count * UNIT_PRICE);

  function increment() {
    setCount(count + 1);
  }

  function decrement() {
    const newCount = count - 1;
    if (newCount >= 4) {
      setCount(newCount);
    }
  }

  useEffect(() => {
    if (count === 4) {
      setShowText(true);
    } else {
      setShowText(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count]);

  return (
    <>
      {showDesktop ? (
        <CDMPStudyProgramCheckoutRow
          count={count}
          setInputValue={setInputValue}
          setCount={setCount}
          price={price}
          showText={showText}
          history={history}
          setStep={setStep}
          setShowText={setShowText}
        />
      ) : (
        <CDMPStudyProgramCheckoutMobile
          count={count}
          setInputValue={setInputValue}
          increment={increment}
          decrement={decrement}
          price={price}
          history={history}
          setStep={setStep}
          setShowText={setShowText}
        />
      )}
    </>
  );
};

export default CDMPStudyProgramCheckoutController;
