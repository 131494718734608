const eventTypes: eventTypeDetails[] = [
  {
    type: "Discussion Group",
    location: "virtual",
    hoverText: "Please sign up on Luma to receive the Zoom link",
  },
  {
    type: "CDMP Prep Session",
    location: "virtual",
    hoverText: "Please sign up on Luma to receive the Zoom link",
  },
  {
    type: "Bootcamp",
    location: "virtual",
    hoverText: "Please register to receive the Zoom link",
  },
  {
    type: "presentation",
    location: "virtual",
    hoverText: "Please register to receive the Zoom link",
  },
  {
    type: "other",
    location: null,
    hoverText: null,
  },
];

export default eventTypes;
