import { Link } from "react-router-dom";

export default function EventDescription({ eventObj, eventsPage }) {
  // let doubleMonth = false;
  // if (eventObj.end.getMonth() === 0 || eventObj.start.getMonth() === 11) {
  //   doubleMonth = true;
  // }

  return (
    <div className={eventsPage ? "blog events-page" : "event-description"} style={{ marginTop: eventsPage ? 4 : 16 }}>
      {eventObj.id.includes("bootcamp") ? (
        <>
          <p>
            Our CDMP Bootcamp provides an accelerated option to study each of the 14 chapters of the{" "}
            <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
              <em>DMBOK</em>
            </a>{" "}
            tested on the{" "}
            <Link to="/resources/cdmp-fundamentals-exam" target="_blank">
              CDMP Fundamentals Exam
            </Link>
            .
          </p>
          <p>
            The two-day CDMP Bootcamp will take place on Friday, Jan 10 and Saturday, Jan 11 from 9am to 4pm ET over
            Zoom. Instructors{" "}
            <Link to="/about" target="_blank">
              John O'Donovan and Nicole Janeway Bills
            </Link>{" "}
            will review the most important concepts from the <em>DMBOK</em>, try extensive practice questions for each
            chapter, and provide guidance on how to approach the exam.
          </p>
        </>
      ) : eventObj.id.includes("discussion-group") ? (
        <>
          <p>
            Join us for an hour long discussion about <strong>{eventObj.chapterName}</strong> (ch.{" "}
            {eventObj.chapterNumber}) of the{" "}
            <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
              <em>DMBOK</em>
            </a>
            .
          </p>
          <p>
            We'll kick things off with introductions right at the top of the hour. This will be followed by conversation
            about <strong>{eventObj.chapterName}</strong> (ch. {eventObj.chapterNumber}). We'll discuss the aspects of
            this chapter you need to understand for the{" "}
            <Link to="/resources/cdmp-fundamentals-exam" target="_blank">
              CDMP exam
            </Link>
            .
          </p>
          <p>We can also use this time to talk through any Data Strategy challenges you're facing at work.</p>
        </>
      ) : eventObj.id.includes("qa") ? (
        <>
          <p>
            Let's talk about the process of getting recognized as a Certified Data Management Professional (
            <Link to="/resources/cdmp-fundamentals-exam" target="_blank">
              CDMP
            </Link>
            ).
          </p>
          <p>
            ​​In this session, we will talk about the new exam platform, BrightSpace, and changes to the{" "}
            <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
              <em>DMBOK</em>
            </a>{" "}
            that will impact the exam starting in October 2024.
          </p>
          <p>
            <strong>You must sign up</strong> in order to receive the link to the event.
          </p>
        </>
      ) : eventObj.id.includes("chatGPT") ? (
        <p>​A brief exploration of how data practitioners can use ChatGPT to improve their productivity.</p>
      ) : eventObj.id.includes("data-valuation") ? (
        <>
          <p>
            ​A brief exploration of strategies to quantify the value of data assets. Data valuation is a crucial input
            into building a business case. It can help justify the development of data management capabilities and
            support the prioritization of data-related projects.
          </p>
          <p>
            <Link to="/about" target="_blank">
              Nicole Janeway
            </Link>{" "}
            will discuss a range of strategies for data valuation:
          </p>
          <ul>
            <li>Back-of-the-envelope math on benefits, costs, opportunities, and risks</li>
            <li>Market-based approaches such as valuing intellectual property and potential revenue from licensing</li>
            <li>Economic models in terms of spurring development and promoting the public good</li>
            <li>Dimensional considerations such as intrinsic value and potential business value</li>
          </ul>
          <p>
            All in all, the effort of measuring the value of data will help you transform the business' perception of
            data from a cost to an asset.
          </p>
        </>
      ) : eventObj.id.includes("data-governance") ? (
        <>
          <p>
            ​Remember Comparative Literature from your college days? This talk offers a comparison of methodologies to
            deal with common Data Governance challenges. ​We will discuss the pros and cons of different methods for
            Data Governance implementation and change management.
          </p>
        </>
      ) : eventObj.id.includes("cdmp-prep") ? (
        <>
          <p>
            This CDMP Prep Session provides an opportunity to study {eventObj.chapterName}, ch. {eventObj.chapterNumber}{" "}
            of the{" "}
            <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
              <em>DMBOK</em>
            </a>
            .
          </p>
          <p>
            The instructor will review the most important concepts from the chapter and provide guidance on how to
            approach this topic on the{" "}
            <Link to="/resources/cdmp-fundamentals-exam" target="_blank">
              CDMP Fundamentals Exam
            </Link>
            .
          </p>
        </>
      ) : eventObj.id.includes("data-ethics") ? (
        <>
          <p>
            ​A brief exploration of the ethical considerations in data management. We will discuss the{" "}
            <a href="https://www.datagovernance.com/what-is-data-governance/" target="_blank" rel="noreferrer">
              data governance
            </a>{" "}
            implications of the{" "}
            <a href="https://www.datagovernance.com/what-is-data-governance/" target="_blank" rel="noreferrer">
              data ethics
            </a>{" "}
            principles of transparency, fairness, and accountability.
          </p>
          <p>
            <Link to="/about" target="_blank">
              Nicole Janeway
            </Link>{" "}
            will discuss the importance of data ethics in the context of data governance:
          </p>
          <ul>
            <li>Transparency in data collection and processing</li>
            <li>Equity in data access and use</li>
            <li>Accountability for data quality and security</li>
          </ul>
          <p>
            All in all, the effort of integrating data ethics into data governance will help you build trust and
            credibility with stakeholders.
          </p>
        </>
      ) : eventObj.id.includes("business-value") ? (
        <p>
          This free training offers attendees the opportunity to become more familiar with AI Governance through a
          comparison of emerging AI Governance frameworks.
        </p>
      ) : (
        <> </>
      )}
    </div>
  );
}
