import Bills from "../../img/people/nicole_bills.webp";
import Mac from "../../img/people/mac_jordan.webp";
import Roman from "../../img/people/roman_hauksson.webp";
import Xolani from "../../img/people/xolani_holomisa.webp";

const authors = [
  {
    id: "Nicole Janeway",
    image: Bills,
    name: "Nicole Janeway Bills",
    title: "Data Strategy Professionals Founder & CEO",
    description: `Nicole offers a proven track record of applying Data Strategy and related disciplines to solve clients' most pressing challenges. She has worked as a Data Scientist and Project Manager for federal and commercial consulting teams. Her business experience includes natural language processing, cloud computing, statistical testing, pricing analysis, ETL processes, and web and application development.`,
  },
  {
    id: "Mac Jordan",
    image: Mac,
    name: "Mac Jordan",
    title: "Data Strategy Professionals Research Specialist",
    description: `Mac supports Data Strategy Professionals with newsletter writing, course development, and research into Data Management trends.`,
  },
  {
    id: "Roman Hauksson",
    image: Roman,
    name: "Roman Hauksson",
    title: "Guest blogger",
    description: `Roman is interested in using his career to help solve the world's most important and neglected problems and ensure humanity has a long and flourishing future. In particular, he is interested in pursuing technical research to align powerful artificial intelligence systems with human values.`,
  },
  {
    id: "Xolani Holomisa",
    image: Xolani,
    name: "Xolani Holomisa",
    title: "Data Strategy Professionals CDMP Subject Matter Expert",
    description:
      "Xolani is a proven leader in Data Management with several decades of experience.  He is recognized by DAMA for a Practitioner-level pass of the <a href='https://www.datastrategypros.com/resources/cdmp-fundamentals-exam' target='_blank' rel='noreferrer'>CDMP Fundamentals Exam</a>.",
  },
];

const PostAuthorCard: React.FC<{ authors: PostAuthor[] }> = ({ authors }) => {
  return (
    <>
      {authors.slice(0, 1).map((author) => (
        <Card postAuthor={author} />
      ))}
      {authors.length > 1 &&
        authors.slice(1, authors.length).map((author) => (
          <div style={{ marginTop: -20 }}>
            <Card postAuthor={author} />
          </div>
        ))}
    </>
  );
};

const Card: React.FC<{ postAuthor?: PostAuthor }> = ({ postAuthor = "Nicole Janeway" }) => {
  const author = authors.find((author) => author.id === postAuthor);

  return (
    <div className="card about-card" style={{ maxWidth: "1000px", marginTop: 57.6 }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          border: "none",
        }}
      >
        <img
          src={author.image}
          alt={author.name}
          style={{
            borderRadius: "50%",
            margin: "0",
            padding: "10px",
            maxWidth: "215px",
            maxHeight: "215px",
            minWidth: "195px",
            minHeight: "195px",
          }}
        />
      </div>
      <div className="card-content">
        <p className="title card-title" style={{ marginBottom: 0 }}>
          {author.name}
        </p>
        <p style={{ fontSize: "0.95em" }}>
          <em>{author.title}</em>
        </p>
        <div className="content" style={{ paddingTop: "1em", paddingBottom: "1em" }}>
          <p dangerouslySetInnerHTML={{ __html: author.description }} />
        </div>
      </div>
    </div>
  );
};

export default PostAuthorCard;
