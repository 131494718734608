import { useEffect } from "react";

import logo from "../../img/helmet/products/logo_cdmp.png";

import CustomHelmet from "../../components/CustomHelmet";
import Product from "../../components/product-card/ProductCardContainer";

import productsList from "../../data/products";

const products = productsList.filter((product) => product.showOnHomepage);

const AdvanceYourCarer: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const productsToShow: productType[] = [
    products.find((product) => product.name === "Career Coaching"),
    products.find((product) => product.name === "Resume & LinkedIn Review"),
    products.find((product) => product.name === "Data Management Master Class"),
    products.find((product) => product.name === "Data Strategy Workbook"),
    products.find((product) => product.name === "Data Strategy Document Checklist"),
  ];

  return (
    <>
      <CustomHelmet
        description="Data Strategy Professionals provides expert resources and guidance to support your professional development."
        name="Advance your Career"
        link="/advance-your-career"
        img={logo}
        alt="group of friends studying"
      />

      <div className="home-layout" style={{ minHeight: "70vh" }}>
        <p className="title">Advance your Career</p>
        <p style={{ fontSize: "1.3em", margin: "-0.9em 0 1.5em 0" }}>
          Find out how to <strong>elevate your work</strong> as a data professional, <strong>earn recognition</strong>{" "}
          in your job, and <strong>distinguish yourself</strong> in the job market.
        </p>
        <div className="product-catalog">
          {productsToShow.map((productObj, idx) => {
            return <Product productObj={productObj as productType} idx={idx} />;
          })}
        </div>
        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <p>
            Let's meet up and discuss your unique situation. Please submit{" "}
            <a href="https://forms.gle/j4dRvVyEKMtnwuo88" target="_blank" rel="noreferrer">
              this form
            </a>{" "}
            to provide more information about your specific situation and goals.
          </p>
        </div>
        <br />
      </div>
    </>
  );
};

export default AdvanceYourCarer;
