import { Link } from "react-router-dom";

import { formatDate } from "../../../helpers/utils";

const SaleBanner: React.FC = () => {
  const targetDate = new Date(Date.UTC(2025, 0, 24, 14, 0, 0));
  const today = new Date();

  return (
    <>
      {today < targetDate && (
        <div
          style={{
            background: "#072845",
            color: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            textWrap: "balance",
            padding: "0 10px",
            zIndex: 998,
          }}
        >
          <p
            id="sale"
            style={{
              textAlign: "center",
              padding: ".75em 0 .75em 0",
              fontSize: "1.1em",
            }}
          >
            For a limited time, sign up for{" "}
            <Link to="/products/cdmp-small-group-sessions" style={{ color: "white" }}>
              CDMP Small Group Sessions (Q1).
            </Link>
          </p>
        </div>
      )}
    </>
  );
};

export default SaleBanner;
