import { NavLink } from "react-router-dom";

import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";

const NavBurger = ({ hamburgerIsActive, setHamburgerIsActive, nav }) => {
  return (
    <div
      id="nav-burger"
      className={hamburgerIsActive ? "is-active navbar-menu" : "navbar-menu"}
      style={{ zIndex: 1000, width: 160 }}
    >
      <div className="navbar-start" style={{ fontSize: "1.25em" }}>
        {nav.map((item) => (
          <NavBurgerItem
            key={item}
            hamburgerIsActive={hamburgerIsActive}
            setHamburgerIsActive={setHamburgerIsActive}
            item={item}
          />
        ))}

        <NavLink
          className="navbar-item"
          activeClassName="active"
          to="/products"
          onClick={() => setHamburgerIsActive(!hamburgerIsActive)}
        >
          <span>
            <span>
              <ShoppingBasketIcon style={{ fontSize: 10 }} />
            </span>
            <span> </span>
            <strong>Shop</strong>
          </span>
        </NavLink>
      </div>
    </div>
  );
};

const NavBurgerItem = ({ hamburgerIsActive, setHamburgerIsActive, item }) => {
  return (
    <NavLink
      className="navbar-item"
      activeClassName="active"
      to={"/" + item.toLowerCase().replace(/ /g, "-")}
      onClick={() => setHamburgerIsActive(!hamburgerIsActive)}
    >
      {item}
    </NavLink>
  );
};

export default NavBurger;
