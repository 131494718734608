import { useState, useEffect, useContext, useRef } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { LayoutContext } from "../../../contexts/LayoutContext";
import { useMediaQuery } from "@mui/material";

import EventBanner from "./EventBanner";
import SaleBanner from "./SaleBanner";
import NavItem from "../nav-item/NavItem";
import ShopButton from "../shop-button/ShopButton";
import NavBurger from "./NavBurger";

import logo from "../../../img/logos/logo_cdmp.webp";

import events from "../../../data/events";
import navbar from "../../../data/navbar";

const nav = navbar.map((item) => item.name);

export default function Navbar() {
  const [pageType, setPageType] = useState("");
  const [sticky, setSticky] = useState(false);
  const [upcomingEvent, setUpcomingEvent] = useState(null);
  const [hamburgerIsActive, setHamburgerIsActive] = useState(false);
  const [shake, setShake] = useState(false);
  const [showEventBanner, setShowEventBanner] = useState(false);

  const location = useLocation();
  const navbarRef = useRef(null);

  const { setNavHeight } = useContext(LayoutContext);

  const desktop = useMediaQuery("(min-width: 750px)");

  useEffect(() => {
    // Find the upcoming event, if any
    const now = new Date();
    const upcoming = events.find(
      (event) => event.start > now && event.start <= new Date(now.getTime() + 7 * 24 * 60 * 60 * 1000)
    );
    if (upcoming) {
      setShowEventBanner(true);
      setUpcomingEvent(upcoming);
    } else {
      setShowEventBanner(false);
      setUpcomingEvent(null);
    }
  }, []);

  useEffect(() => {
    const navbarElement = navbarRef.current;

    if (navbarElement) {
      const height = navbarElement.getBoundingClientRect().height;
      setNavHeight(height);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navbarRef.current]);

  useEffect(() => {
    if (location.pathname === "/" || location.pathname.includes("/pages/")) {
      setPageType("home");
    } else if (
      location.pathname.includes("landing")
      // || location.pathname === "/start-here"
    ) {
      setPageType("landing");
    } else if (location.pathname.includes("products")) {
      setPageType("product");
    } else {
      setPageType("other");
    }
  }, [location.pathname]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    if (pageType !== "home") {
      setSticky(false);
    }

    if (pageType !== "product") {
      const randomValue = Math.floor(Math.random() * 3);
      if (randomValue === 0) {
        setShake(true);
      } else {
        setShake(false);
      }
    }

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener("scroll", handleScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, pageType]);

  const handleScroll = () => {
    if (pageType === "home") {
      // You can adjust the condition based on your requirement
      if (window.scrollY > 55) {
        // assuming you want to hide the element after 100px of scroll
        setShowEventBanner(false);
      }

      if (window.scrollY > 55) {
        setSticky(true);
      } else if (window.scrollY < 55) {
        setShowEventBanner(true);
        setSticky(false);
      }
    }
  };

  return (
    <>
      <div ref={navbarRef} style={{ position: sticky ? "sticky" : "static", top: 0, zIndex: 999 }}>
        {pageType !== "landing" && (
          <>
            <div style={{ position: pageType === "home" ? "sticky" : "static", top: 0, zIndex: 999 }}>
              <nav className="navbar">
                <div
                  style={{
                    display: "flex",
                    alignContent: "center",
                    justifyContent: "center",
                  }}
                >
                  <NavLink to="/">
                    <img id="header-img" src={logo} alt="hand holding lightbulb" />
                  </NavLink>
                  <NavLink to="/" className="nav-brand">
                    Data Strategy Professionals
                  </NavLink>
                </div>
                {desktop ? (
                  <ul style={{ justifySelf: "end" }}>
                    {nav.map((item) => (
                      <NavItem key={item} itemName={item} />
                    ))}
                    <ShopButton shake={shake} />
                  </ul>
                ) : (
                  <button
                    className={hamburgerIsActive ? "is-active navbar-burger" : "navbar-burger"}
                    onClick={() => setHamburgerIsActive(!hamburgerIsActive)}
                  >
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                  </button>
                )}
              </nav>
            </div>

            {hamburgerIsActive && (
              <NavBurger hamburgerIsActive={hamburgerIsActive} setHamburgerIsActive={setHamburgerIsActive} nav={nav} />
            )}
            <SaleBanner />
            {/* {showEventBanner && upcomingEvent && <EventBanner upcomingEvent={upcomingEvent} />}
            {upcomingEvent && sticky && (
              <div
                style={{
                  height: 57,
                  color:
                    location.pathname === "/"
                      ? "#f9f9f9"
                      : location.pathname === "/page/cdmp"
                      ? "#e9eff1"
                      : "transparent",
                }}
              />
            )} */}
          </>
        )}
      </div>
    </>
  );
}
